@import url('https://fonts.googleapis.com/css?family=Open+Sans');

button[title="Numbered list"],
button[title="Bullet list"],
button[title="Link"],
button[title="Clear formatting"],
button[title="HTML mode"],
select[title="Styles"] {
    display: none !important;
}

.font-bold {
    font-weight: 700 !important;
}

.baner-wrapper-in-edit .rsw-editor {
    border-radius: 0px !important;
    border: none !important;
}

.baner-wrapper-in-edit .rsw-ce {
    background: white !important;
    border: 1px solid #CCCCCC !important;
    font-size: 14px !important;
}

.baner-wrapper-in-edit .rsw-toolbar {
    background: #FBFBFB !important;
    height: 40px;
    margin-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    max-width: 198px;
}

.baner-wrapper-in-edit .rsw-toolbar button {
    width: 30px !important;
    height: 24px !important;
    background: #FFFFFF !important;
    border: 1px solid #EEEEEE !important;
    border-radius: 1px !important;
    margin-left: 4px;
    margin-right: 4px;
}

.baner-wrapper-in-edit .rsw-separator {
    display: none !important;
}

.baner-wrapper .rsw-toolbar {
    display: none;
}

.baner-wrapper .rsw-ce {
    padding: 0;
    border: none;
}

.baner-wrapper .rsw-editor {
    border: none;
}

.user-info {
    background-color: #ffffff;
    border-radius: 4px;
    margin: 20px;
    padding: 20px;
}

body {
    background-color: #F8F8F8;
    font-family: "Open Sans";
    color: #000000;
    overflow-x: hidden;
    overflow-y: auto;
}

.main {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-left: 20px;
}

.min-width-280 {
    min-width: 280px;
}

.min-width-520 {
    min-width: 520px;
}

.header {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 14px;
}

.sub-header {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

.baner-header {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
}

.baner-sub-header {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

.baner-regular {
    font-size: 13px;
}

.baner-new {
    width: 70px;
    height: 25px;
    background: #33C83D;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.baner-promo {
    width: 80px;
    height: 25px;
    background: #FF8700;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.baner-image {
    margin-top: 10px;
    margin-bottom: 20px;
}

.target-header {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}

.target-points {
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
    color: #6C6C6C;
}

.mt-50 {
    margin-top: 50px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-10 {
    margin-top: 10px;
}

.progress-bar-container {
    height: 12px;
    margin-top: 6px;
    background: #E5E5E5;
    border-radius: 9.5px;
    position: relative;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    background: #7ED321;
    height: 12px;
    border-radius: 9.5px;
}

.baner-edit-btn {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    cursor: pointer;
    color: #4A90E2;
    margin-top: 20px;
}

.static-baner-edit-btn {
    margin-right: 20px;
    margin-bottom: 20px;
}

.summary-regular {
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
}

.add-new-member {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #4A90E2;

    margin-top: 14px;
    margin-bottom: 30px;
    cursor: pointer;
}

.mr6 {
    margin-right: 6px;
}

.mb14 {
    margin-bottom: 14px;
}

.mt-14 {
    margin-top: 14px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb-35 {
    margin-bottom: 35px;
}

.w-100 {
    width: 100%;
}

.border-radius {
    border-radius: 4px;
}

.static-link {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;

    text-decoration-line: underline;

    color: #292929;
    cursor: pointer;
}

.static-link-inactive {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;

    text-decoration-line: underline;
    color: #9D9D9D;
}

.button {
    width: 100%;
    border: none;
    height: 30px;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #FFFFFF;
}

textarea:disabled {
    border: none;
    background: transparent;
    color: black;
    padding: 0;
}

.button:disabled {
    background: #D8D8D8;
}

.baner-input {
    width: 100%;
    border: 1px solid #CCCCCC;
    padding-left: 14px;
    height: 40px;
}

.baner-input:focus {
    border: 1px solid #CCCCCC;
    outline: none;
}

.popup-input {
    width: 100%;
    border: 1px solid #CCCCCC;
    padding-left: 14px;
    height: 150px;
    font-size: 13px;
    resize: none;
}

.popup-input:focus {
    border: 1px solid #CCCCCC;
    outline: none;
}

.baner-input-header {
    font-weight: 700;
    margin-bottom: 8px;
}

.baner-input-subheader {
    font-weight: 700;
    margin-bottom: 13px;
}


.button-red {
    background: #D0021B;
}

.button:hover {
    color: #FFFFFF
}

.relative-wrapper {
    position: relative;
}

.relative-editor-wrapper {
    position: relative;
}

.relative-wrapper img {
    opacity: 30%;
}

.relative-wrapper svg {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: white;
    cursor: pointer;
}

.separator {
    border: 1px solid #CCCCCC;
}

.horizontal-separator {
    border: 1px solid #909090;
    height: 2px;
    margin-bottom: 6px;
}

.table-tab-header-static {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    padding-top: 5px;
}

.table-tab-header {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 30px;
    color: #808085;
}

.table-tab-header:hover {
    text-decoration: underline;
    cursor: pointer;
}

.table-tab-header-active {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    height: 30px;
    color: #000;
    border-bottom: 3px solid red;
}

.form-popup-header {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    height: 30px;
    color: #808085;
}

.form-popup-header:hover {
    text-decoration: underline;
    cursor: pointer;
}

.form-popup-header-active {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    height: 40px;
    color: #000;
    border-bottom: 3px solid red;
}

.table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #4A4A4A;
}

.table-header:hover {
    cursor: pointer;
    text-decoration: underline;
}

.table-header-input {
    height: 30px;

    background: #FFFFFF;
    border: 1px solid #4A4A4A;
    border-radius: 3px;

    font-style: normal;
    font-size: 13px;
    line-height: 25px;

    padding-left: 5px;
}

.w-125 {
    width: 125px;
}

.w-245 {
    width: 245px
}

.w-165 {
    width: 165px;
}

.pr-15 {
    padding-right: 15px;
}

.user-details-icon svg {
    width: 20px;
    height: 20px;
    color: black;
    cursor: pointer;
}

.raport-button {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;

    color: #4A90E2;
    cursor: pointer;
}

.raport-button:hover {
    text-decoration: underline;
}

.raport-button svg {
    height: 15px;
    width: 15px;
}

.table-input-wrapper svg {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 110px;
    color: #6C6C6C;
}

.table-input-wrapper-mid svg {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 150px;
    color: #6C6C6C;
}

.table-input-wrapper-wide svg {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 230px;
    color: #6C6C6C;
}

.table-header-input:focus {
    outline: none;
}

.table-separator {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #6C6C6C;
}

.mt-30 {
    margin-top: 30px;
}

.mt-15 {
    margin-top: 15px;
}

.button:visited {
    color: #FFFFFF
}

.button-grey {
    background: #4A4A4A;
}

.center-table-cell {
    text-align: center;
    vertical-align: middle;
}

.baner-wrapper {
    background-color: #ffffff;
    border-radius: 4px;
    margin: 20px;
    padding: 20px;
}

.baner-wrapper-border {
    border: 1px dashed #C4C4C4;
}

.baner-wrapper-in-edit {
    background-color: #EEEEEE;
    border: none;
    margin: 20px;
    padding: 20px;
    position: relative;
}

.baner-wrapper-no-padding {
    background-color: #ffffff;
    border-radius: 4px;
    margin: 20px;
    position: relative;
}



div.select-container {
    width: 130px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.select-button {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    width: 100%;
    position: relative;
    text-align: left;
    padding: 7px 10px;
}

.select-button:hover {
    cursor: pointer;
}

.select-sort-button {
    background: #FFFFFF;
    border: none;
}

.select-sort-button:hover {
    cursor: pointer;
}

.sort-select-prefix {
    height: fit-content;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #6C6C6C;
}

.select-reward-search-input-wrapper {
    width: 50%;
    position: relative;
}

.select-reward-search-input-wrapper svg {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 5px;
    top: 5px;
    color: #000;
}

.select-reward-search-input {
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    padding-left: 30px;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
}

.select-reward-search-input:focus {
    outline: none;
}

.select-reward-search-input::placeholder {
    font-weight: 400;
    font-size: 13px;
    color: #6C6C6C;
}

.reward-popup-open {
    height: 700px;
}

ul.options {
    border: none;
    display: none;
    list-style: none;
    padding: 5px 0px;
    position: absolute;
    width: auto;
    background: #FFFFFF;
    box-shadow: 0px 12px 22px rgba(92, 92, 92, 0.416469);
    border-radius: 3px;
    z-index: 1;
    max-height: 298px;
    overflow-y: auto;
    scrollbar-color: #979797 #979797;
    scrollbar-width: none;
}

ul.options::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

ul.options::-webkit-scrollbar-thumb {
    background: #979797;
    border: 1px solid #979797;
    border-radius: 16.5px;
}

ul.options li {
    height: 36px;
    padding: 7px 15px;
}

ul.show {
    display: block;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
    background: #FBFBFB;
    cursor: pointer;
}

.option-empty {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 4px;
}

.sort-option-empty {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 4px;
}

.sort-select-search-input {
    height: 30px;
    margin: 2px 15px;
    font-size: 12px;
}

.sort-select-search-input:focus {
    outline: none;
}

.option-new {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    width: 70px;
    background: #33C83D;
    border-radius: 4px;
    color: #FFF;
    padding: 4px;
}

.option-promo {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    width: 80px;
    background: #FF8700;
    border-radius: 4px;
    color: #FFF;
    padding: 4px;
}

.text-orange {
    color: #FF8700;
}

.text-green {
    color: #7ED321;
}

.text-red {
    color: #D8283D;
}

.baner-edit-btns {
    margin-top: 24px;
}

.finish-action-btn {
    width: 140px
}

.button-transparent {
    background-color: transparent;
    color: black;
}

.button-transparent:hover {
    text-decoration: underline;
    color: black;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 60%;
    background: white;
}

.error-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 100;
    padding-left: 50px;
    padding-top: 50px;
    font-weight: bold;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.popup-open {
    position: absolute;
    max-width: 800px;
    width: 570px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;

    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.272072);
    padding: 24px 36px;
    overflow: auto;
}

.error-msg-open {
    position: absolute;
    max-width: 200px;
    width: 200px;
    min-height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ff0000;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 27%);
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
    color: white;
    text-align: center;
    padding: 20px;
}

.popup-open-form {
    position: absolute;
    max-width: 800px;
    width: 700px;
    min-height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.272072);
    padding: 24px 36px;
}

.popup-content object {
    height: 400px;
    max-height: 100%;
}

.popup-content-reward {
    overflow-y: auto;
}

.popup-content-reward::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

.popup-content-reward::-webkit-scrollbar-thumb {
    background: #979797;
    border: 1px solid #979797;
    border-radius: 16.5px;
}

.popup-open-agreement {
    position: absolute;
    max-width: 1600px;
    width: 1200px;
    min-height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;

    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.272072);
    padding: 24px 36px;
    overflow: auto;
}

.popup-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}

.popup-close-icon-wrapper svg {
    width: 25px;
    height: 25px;
    align-self: center;
}

.p-20-px {
    padding: 20px;
}

.edit-input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #4A4A4A;
    margin-bottom: 5px;
}

.list-wrapper table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
}

tbody tr td {
    width: 25%;
}

tbody tr {
    border-radius: 4px;
    height: 55px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

tbody tr.main-table {
    background: white;
}

tbody tr.secondary-table {
    background: #E6E6E6;
}

.pl-28 {
    padding-left: 28px;
}

.mt-20 {
    margin-top: 20px;
}

.form-list-wrapper {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
}

.form-list-wrapper svg {
    width: 20px;
    height: 20px;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #eaeaea;
    background-color: #eaeaea;
    cursor: pointer;
}

.custom-checkbox:hover {
    background-color: #eaeaea;
    border: 2px solid #9B9B9B
}

.custom-checkbox-active {
    background-color: #D0021B;
}

.custom-checkbox-active:hover {
    background-color: #D0021B;
    border: 2px solid #9B9B9B
}

.form-row {
    background-color: #F8F8F8;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 13px;
}

.mr-20 {
    margin-right: 20px;
}

.circle {
    height: 15px;
    width: 15px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid black;
}

.red-circle {
    height: 15px;
    width: 15px;
    background-color: #D0021B;
    border-radius: 50%;
    border: 1px solid #D0021B;
    display: inline-block;
}

.green-circle {
    height: 15px;
    width: 15px;
    background-color: #33C83D;
    border-radius: 50%;
    border: 1px solid #33C83D;
    display: inline-block;
}

.form-details-content {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 150px;
}

.form-details-content::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

.form-details-content::-webkit-scrollbar-thumb {
    background: #979797;
    border: 1px solid #979797;
    border-radius: 16.5px;
}

.form-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 24px;
    margin-left: 8px;
}

.form-input-wrapper {
    margin-left: 8px;
    margin-right: 8px;
}

.form-input-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 5px;
}

.form-input {
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
    width: 100%;
}

.form-input-invalid {
    background: #FFCCCB;
    border: 1px solid #FF0000;
}

.form-input:focus {
    outline: none;
}

.form-input:disabled {
    background: #F9F9F9;
}

.form-info svg {
    height: 20px;
    width: 20px;
}

.form-info-label {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    margin-right: 10px;
}

.form-summary {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.wp-10 {
    width: 10%;
}

.wp-15 {
    width: 15%;
}

.wp-20 {
    width: 20%;
}

.wp-25 {
    width: 25%;
}

.wp-30 {
    width: 30%;
}

.wp-35 {
    width: 35%;
}

.wp-45 {
    width: 45%;
}

.wp-50 {
    width: 50%;
}

.wp-55 {
    width: 55%;
}

.wp-65 {
    width: 65%;
}

.wp-75 {
    width: 75%;
}

.wp-80 {
    width: 80%;
}

.select-rewards-points {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.select-rewards-subheader {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.reward-list-item {
    height: 35px;
    min-height: 35px;
}

.reward-list-item:nth-child(even) {
    background: #fff
}

.reward-list-item:nth-child(odd) {
    background: #F8F8F8
}

.reward-code {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.reward-name {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
    display: block !important;
}

.reward-points {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding-right: 20px;
}

.clickable-circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #909090;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clickable-inner-circle {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #D8283D;
}

.clickable-circle:hover {
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.272072);
}

.reservation-number-container {
    position: relative;
    width: 210px;
}

.reservation-number-container svg {
    position: absolute;
    right: 30px;
    top: 5px;
    height: 20px;
    width: 20px;
    right: 5px;
    top: 5px;
    color: #6C6C6C;
    cursor: pointer;
}

.user-details-date {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #9D9D9D;
}

.user-details-popup-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #9D9D9D;
}

.user-details-email {
    margin-left: 5px;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    color: #9D9D9D;
}

.user-details-address {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #292929;
}

.user-details-earned-points {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.user-details-potential-points {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.user-details-action-link {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #4A90E2;
    cursor: pointer;
    width: fit-content;
}

.note-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.add-note-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    padding-right: 20px;
}

.add-note-textarea {
    width: 100%;
    border: 1px solid #CCCCCC;
    padding-left: 10px;
    padding-top: 10px;
    height: 80px;
    font-size: 13px;
    resize: none;
}

.add-note-textarea:focus {
    outline: none;
}

.note-attachment a {
    text-decoration: none;
    color: #4A90E2;
}

.reward-form-status-text {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
}

.logo {
    max-width: 200px;
}

.react-datepicker__input-container input {
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    height: 30px;
    padding-left: 10px;
    width: 100%;
}

.react-datepicker-ignore-onclickoutside:focus {
    outline: none;
}

.year-carousel-item {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 13px;
    color: #808085;
    margin: 0px 3px;
}

.year-carousel-item-active {
    border-bottom: 2px solid #D8283D;
    font-weight: 700;
    color: #000;
}

.datepicker-wrapper {
    width: 95px;
    margin-right: 15px;
}

.base-form-input-label {
    height: 30px;
    line-height: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.reervation-table-wrapper {
    overflow-y: scroll;
    max-height: 750px;
}

.stats-relative-container {
    position: relative;
}

.year-carousel-container svg {
    cursor: pointer;
}

.add-user-popup {
    min-height: 500px;
}

.unsuspend-user-popup {
    min-height: 500px;
}